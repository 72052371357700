import React, { useState, useRef } from 'react';
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import { Edit, Delete, Info } from '@mui/icons-material';

const EntityList = ({ projects, files, fileChecks, fileStrings, packages, settings, onEdit, onDelete, onNew, onShowIgnores }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState({ type: '', id: null });

  const projectsRef = useRef(null);
  const filesRef = useRef(null);
  const fileChecksRef = useRef(null);
  const fileStringsRef = useRef(null);
  const packagesRef = useRef(null);
  const settingsRef = useRef(null);

  const handleOpenDialog = (type, id) => {
    setEntityToDelete({ type, id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEntityToDelete({ type: '', id: null });
  };

  const handleConfirmDelete = () => {
    onDelete(entityToDelete.type, entityToDelete.id);
    handleCloseDialog();
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const linkStyle = {
    cursor: 'pointer',
    color: 'primary.main',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  return (
    <Container>
      <Typography variant="h4">Entities</Typography>
      
      <div style={{ backgroundColor: '#d8e5ff', padding: '0px', margin: '0px' }}>
        <Box display="flex" justifyContent="space-around" marginY="0px">
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(projectsRef)}>
            Projects
          </Link>
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(filesRef)}>
            Files
          </Link>
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(fileChecksRef)}>
            File Checks
          </Link>
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(fileStringsRef)}>
            File Strings
          </Link>
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(packagesRef)}>
            Packages
          </Link>
          <Link component="button" variant="body1" sx={linkStyle} onClick={() => scrollToSection(settingsRef)}>
            Settings
          </Link>
        </Box>
      </div>

      <div ref={projectsRef} style={{ backgroundColor: '#d0f8ff', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Projects</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('project')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {projects.map((project) => (
            <ListItem 
              key={project.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={project.stringID} />
              <ListItemText primary={project.name} sx={{ marginY: '0px' }} secondary={project.url} style={{ textAlign: 'left', width: '100%', marginLeft: '20px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onShowIgnores(project)} title="View Ignores">
                  <Info />
                </IconButton>
                <IconButton edge="end" onClick={() => onEdit('project', project)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('project', project.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div ref={filesRef} style={{ backgroundColor: '#faebd7', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Files</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('file')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {files.map((file) => (
            <ListItem 
              key={file.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={file.name} secondary={`${file.format} - ${file.filePath}, enabled: ${file.isEnabled}`} sx={{ marginY: '0px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('file', file)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('file', file.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div ref={fileChecksRef} style={{ backgroundColor: '#f1d7fa', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">File Checks</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('fileCheck')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {fileChecks.map((file) => (
            <ListItem 
              key={file.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={file.name} secondary={`${file.filePath}, enabled: ${file.isEnabled}`} sx={{ marginY: '0px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('fileCheck', file)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('fileCheck', file.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div ref={fileStringsRef} style={{ backgroundColor: '#fad7d7', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">File Strings</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('fileString')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {fileStrings.map((file) => (
            <ListItem 
              key={file.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={file.name} secondary={file.filePath + ', string: ' + file.string + ', enabled:' + file.isEnabled } sx={{ marginY: '0px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('fileString', file)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('fileString', file.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div ref={packagesRef} style={{ backgroundColor: '#d7fae2', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Packages</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('package')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {packages.map((pkg) => (
            <ListItem 
              key={pkg.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={pkg.name} secondary={'url: ' + pkg.url + ', hash: ' + pkg.hash + ', version: ' + pkg.version + ', onlyCheckExists: ' + pkg.onlyCheckExists + ', mustNotExist: ' + pkg.mustNotExist + ', enabled: ' + pkg.isEnabled} sx={{ marginY: '0px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('package', pkg)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('package', pkg.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <div ref={settingsRef} style={{ backgroundColor: '#f5f5dc', padding: '10px', marginTop: '10px' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Settings</Typography>
          <Button variant="contained" color="primary" onClick={() => onNew('setting')} style={{ marginLeft: '10px' }}>
            New
          </Button>
        </Box>
        <List>
          {settings.map((setting) => (
            <ListItem
              key={setting.id}
              sx={{
                paddingY: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
                },
              }}
            >
              <ListItemText primary={setting.param} secondary={setting.value} sx={{ marginY: '0px' }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onEdit('setting', setting)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleOpenDialog('setting', setting.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this entity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default EntityList;

