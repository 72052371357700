import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import config from '../config';

const IgnoreForm = ({ open, onClose, onSave, ignore, projectId, error, auth }) => {
  const [typeID, setTypeID] = useState('');
  const [entityID, setEntityID] = useState('');
  const [entityOptions, setEntityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  // Effect to populate form fields when editing
  useEffect(() => {
    if (ignore) {
      setTypeID(ignore.typeID);
      setEntityID(ignore.entityID.toString()); // Ensure entityID is a string
      setIsEditMode(true);
    } else {
      setTypeID('');
      setEntityID('');
      setIsEditMode(false);
    }
    setEntityOptions([]);
    setFetchError('');
  }, [ignore, open]);

  // Effect to fetch entity options when typeID changes
  useEffect(() => {
    const fetchEntityOptions = async () => {
      if (!typeID) {
        setEntityOptions([]);
        return;
      }

      setIsLoading(true);
      setFetchError('');

      let endpoint = '';
      switch (typeID) {
        case 'fileCheck':
          endpoint = `${config.API_BASE_URL}/fileChecks`;
          break;
        case 'fileString':
          endpoint = `${config.API_BASE_URL}/fileStrings`;
          break;
        case 'package':
          endpoint = `${config.API_BASE_URL}/packages`;
          break;
        case 'setting':
          endpoint = `${config.API_BASE_URL}/settings`;
          break;
        default:
          setEntityOptions([]);
          setIsLoading(false);
          return;
      }

      try {
        const response = await axios.get(endpoint, { auth: auth });
        setEntityOptions(response.data.list || []);

        // **Set entityID only if in edit mode**
        if (isEditMode && ignore) {
          const existingEntity = response.data.list.find(option => option.id === ignore.entityID);
          if (existingEntity) {
            setEntityID(ignore.entityID.toString());
          } else {
            // Optionally handle case where existing entity is not found in fetched list
            setEntityID('');
          }
        }
      } catch (err) {
        console.error(`Error fetching ${typeID} options:`, err);
        setFetchError('Failed to fetch entity options. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchEntityOptions();
  }, [typeID, isEditMode, ignore, auth]);

  const handleSubmit = () => {
    // Basic validation
    if (!typeID || !entityID) {
      return;
    }
    const data = {
      projectID: projectId,
      typeID,
      entityID: parseInt(entityID, 10), // Convert back to integer
    };
    onSave(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{ignore ? 'Edit Ignore' : 'New Ignore'}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ mt: 2 }}>
          {/* Hidden Project ID Field */}
          <input type="hidden" value={projectId} />

          {/* Type ID Select */}
          <FormControl fullWidth margin="dense">
            <InputLabel id="type-id-label">Type</InputLabel>
            <Select
              labelId="type-id-label"
              value={typeID}
              label="Type"
              onChange={(e) => setTypeID(e.target.value)}
            >
              <MenuItem value="fileCheck">File Check</MenuItem>
              <MenuItem value="fileString">File String</MenuItem>
              <MenuItem value="package">Package</MenuItem>
              <MenuItem value="setting">Setting</MenuItem>
            </Select>
          </FormControl>

          {/* Entity ID Select */}
          <FormControl fullWidth margin="dense" disabled={!typeID || isLoading}>
            <InputLabel id="entity-id-label">
              {isLoading ? 'Loading...' : 'Entity'}
            </InputLabel>
            {isLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center" paddingY={2}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Select
                labelId="entity-id-label"
                value={entityID}
                label="Entity"
                onChange={(e) => setEntityID(e.target.value)}
              >
                {entityOptions.length > 0 ? (
                  entityOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id.toString()}>
                      {typeID === 'setting' ? option.param : option.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </Select>
            )}
          </FormControl>

          {/* Display fetch errors */}
          {fetchError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {fetchError}
            </Typography>
          )}

          {/* Display API errors */}
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!typeID || !entityID || isLoading}
        >
          {ignore ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IgnoreForm;

