import React, { useState } from 'react';
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Edit, Delete, Add, Close } from '@mui/icons-material';
import IgnoreForm from './IgnoreForm';
import axios from 'axios';
import config from '../config';

const IgnoreList = ({ project, ignores, setIgnores, onClose, auth, fetchIgnores }) => {
  const [openForm, setOpenForm] = useState(false);
  const [editingIgnore, setEditingIgnore] = useState(null);
  const [error, setError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null });

  const handleOpenForm = (ignore = null) => {
    setEditingIgnore(ignore);
    setOpenForm(true);
    setError('');
  };

  const handleCloseForm = () => {
    setEditingIgnore(null);
    setOpenForm(false);
    setError('');
  };

  const handleSave = async (data) => {
    try {
      if (editingIgnore) {
        // Update existing ignore
        await axios.put(`${config.API_BASE_URL}/project/${project.id}/ignore/${editingIgnore.id}`, data, {
          auth: auth,
        });
      } else {
        // Create new ignore
        await axios.post(`${config.API_BASE_URL}/project/${project.id}/ignore`, data, {
          auth: auth,
        });
      }
      handleCloseForm();
      fetchIgnores(project.id);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errorCode) {
        setError(`Error: ${err.response.data.errorCode}`);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${config.API_BASE_URL}/project/${project.id}/ignore/${confirmDelete.id}`, {
        auth: auth,
      });
      setConfirmDelete({ open: false, id: null });
      fetchIgnores(project.id);
    } catch (err) {
      console.error('Error deleting ignore:', err);
    }
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginY="20px">
        <Typography variant="h5">Ignores for Project: {project.name}</Typography>
        <Button variant="contained" color="secondary" startIcon={<Close />} onClick={onClose}>
          Back
        </Button>
      </Box>
      <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => handleOpenForm()}>
        New Ignore
      </Button>
      <List>
        {ignores.map((ignore) => (
          <ListItem
            key={ignore.id}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            <ListItemText primary={ignore.name} secondary={`Type ID: ${ignore.typeID}`} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => handleOpenForm(ignore)} title="Edit Ignore">
                <Edit />
              </IconButton>
              <IconButton edge="end" onClick={() => setConfirmDelete({ open: true, id: ignore.id })} title="Delete Ignore">
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {/* Ignore Form Dialog */}
      <IgnoreForm
        open={openForm}
        onClose={handleCloseForm}
        onSave={handleSave}
        ignore={editingIgnore}
        projectId={project.id}
        error={error}
        auth={auth}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={confirmDelete.open}
        onClose={() => setConfirmDelete({ open: false, id: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this ignore?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete({ open: false, id: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default IgnoreList;
